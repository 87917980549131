import React from "react"
import { Link } from "react-router-dom"
import logo from "../assets/NativADevs-2.png"
//
import PrimaryPinkButton from "./primary-pink-button"
import "./header.css"

const Header = (props) => {
  return (
    <div data-role="Header" className="header-header">
      <nav className="header-nav">
        <div className="header-container">
          <Link to="/" className="header-navlink">
             <img className="logo" src="https://cdn.aglty.io/ncz4tum0/logos/Logo ND.svg" alt="logo" />
          </Link>
          <div className="header-menu">
            <a href="#proven-process" className="header-navlink1">
              <span>Process</span>
              <br></br>
            </a>
            <a href="#services" className="header-navlink2">
              <span>Services</span>
              <br></br>
            </a>
            <a href="#tech-stack" className="header-navlink3">
              <span>Technologies</span>
              <br></br>
            </a>
          </div>
          <div className="header-container1">
            <div className="header-container2">
              {/* <PrimaryPinkButton button="Contact us"></PrimaryPinkButton> */}
              <a
                className="primary-pink-button-button button ButtonSmall"
                href="#home-form">
                Contact Us
              </a>
            </div>
            <div data-role="BurgerMenu" className="header-burger-menu">
              <svg viewBox="0 0 1024 1024" className="header-icon">
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
          </div>
        </div>
      </nav>
      <div data-role="MobileMenu" className="header-mobile-menu">
        <div className="header-top">
          <Link to="/" className="header-navlink4 Large">
            Nativa Devs
          </Link>
          <div data-role="CloseMobileMenu" className="header-close-menu">
            <svg viewBox="0 0 1024 1024" className="header-icon2">
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
        </div>
        <div className="header-mid">
          <div className="header-menu1">
            {/* <Link to="/" className="header-navlink5 Large">
              Home
            </Link>
            <Link to="/profile" className="header-navlink6 Large">
              Profile
            </Link>
            <Link to="/coming-soon" className="header-navlink7 Large">
              Coming Soon
            </Link> */}
            <a href="#proven-process" className="header-navlink1">
              <span>Process</span>
              <br></br>
            </a>
            <a href="#services" className="header-navlink2">
              <span>Services</span>
              <br></br>
            </a>
            <a href="#tech-stack" className="header-navlink3">
              <span>Technologies</span>
              <br></br>
            </a>
          </div>
        </div>
        <div className="header-bot">
          {/* <PrimaryPinkButton button="buy now"></PrimaryPinkButton> */}
          <a
            className="primary-pink-button-button button ButtonSmall"
            href="#home-form">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default Header
